<template>
  <v-card>
    <v-card-title v-if="!hideDialogButton">{{
      $t("contents.title")
    }}</v-card-title>

    <v-card-text>
      <v-text-field
        v-model="name"
        :label="$t('contents.fields.name')"
        prepend-inner-icon="mdi-domain"
      />
    </v-card-text>

    <v-card-text>
      <v-text-field
        v-model="weight"
        type="number"
        :label="$t('contents.fields.weightByCubicMeter')"
        prepend-inner-icon="mdi-counter"
      />
    </v-card-text>

    <v-card-text>
      <v-switch
        v-model="globalContent"
        :label="$t('contents.fields.globalContent')"
        :hint="$t('contents.hints.globalContent')"
        persistent-hint
      />
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        v-if="!hideDialogButton"
        color="grey darken-1"
        text
        @click="$emit('close-dialog')"
      >
        {{ $t("common.cancel") }}
      </v-btn>

      <v-btn color="blue darken-1" text @click="save()">
        {{ edit ? $t("common.save") : $t("common.add") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "ContentForm",

  props: {
    content: {
      type: Object,
      default: null,
    },

    edit: {
      type: Boolean,
      default: false,
    },

    hideDialogButton: {
      type: Boolean,
      default: true,
    },
  },

  model: { props: "content" },

  data() {
    return {
      name: "",
      weight: 0.0,
      globalContent: false,

      errors: [],
    };
  },

  methods: {
    ...mapActions("contents", ["createSiloContent", "updateSiloContent"]),

    async save() {
      let err = [];
      if (this.name == "" || this.name == null) {
        err.push(this.$t("contents.errors.name"));
      }

      if (this.weight == "" || this.name == null) {
        err.push(this.$t("contents.errors.weight"));
      }

      if (err.length > 0) {
        this.errors = err;
        return;
      }

      let payload = {
        name: this.name,
        weightByCubicMeter: this.weight,
        globalContent: this.globalContent,
      };

      if (!this.edit) await this.createSiloContent(payload);
      else
        await this.updateSiloContent({
          siloContentId: this.content.siloContentId,
          payload: payload,
        });

      if (!this.hideDialogButton) {
        this.$emit("close-dialog");
      }
    },
  },

  created() {
    if (this.content != null) {
      this.name = this.content.name;
      this.weight = this.content.weightByCubicMeter;
      this.globalContent = this.content.globalContent;
    }
  },
};
</script>