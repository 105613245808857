<template>
  <content-form />
</template>

<script>
import ContentForm from "./ContentForm.vue";

export default {
  name: "ContentCreate",

  components: {
    ContentForm,
  },
};
</script>